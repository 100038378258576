const safeRedirectBaseUrl = {
  integration: 'https://apps.dev.docusign.net/',
  stage: 'https://apps-s.docusign.com/',
  demo: 'https://apps-d.docusign.com/',
  production: 'https://apps.docusign.com/',
}

export const getSafeRedirectBaseUrl = (env) => {
  switch (env) {
    case 'hqtest0.dev':
    case 'hqtest1.dev':
    case 'hqtest2.dev':
    case 'hqtest3.dev':
    case 'tk1r1s1':
    case 'tk1r2s1':
    case 'local':
    case 'powerforms01.hqtest.tst':
    case 'powerforms02.hqtest.tst':
    case 'powerforms03.hqtest.tst':
      return safeRedirectBaseUrl.integration

    case 'stage':
    case 'stage-app':
      return safeRedirectBaseUrl.stage

    case 'demo':
    case 'demo-app':
      return safeRedirectBaseUrl.demo

    case 'na1':
    case 'NA1':
    case 'na2':
    case 'NA2':
    case 'na3':
    case 'NA3':
    case 'na4':
    case 'NA4':
    case 'na3-eu1':
    case 'fed':
    case 'eu':
    case 'EU':
    case 'au':
    case 'AU':
    case 'ca':
    case 'CA':
    case 'www':
    case 'WWW':
    case 'na1-app':
    case 'na2-app':
    case 'na3-app':
    case 'na4-app':
    case 'eu-app':
    case 'au-app':
    case 'ca-app':
    case 'jp1':
      return safeRedirectBaseUrl.production

    default:
      return safeRedirectBaseUrl.production
  }
}
