import React from "react";
import { Provider, connect } from "react-redux";
import { Route, Switch } from "react-router-dom";
import { ConnectedRouter } from "react-router-redux";
import { bindActionCreators } from "redux";
import {InkDocuSignTheme, Theme} from "@ds/ui";
import store, { history } from "../store";
import TranslationProvider from "../lib/TranslationProvider";
import PowerformsSubmissionFormContainer from "./PowerformsSubmissionForm";
import PowerformError from "./PowerformError";
import LoadingSpinner from "./LoadingSpinner";
import NDSEHeaderContainer from "./NDSEHeader";
import NotificationsContainer from "./Notifications";
import FooterContainer from "./Footer";
import { Footer } from "@ds/components";
import actions from "../actions";
import { getErrorCode } from "../reducers";
import { getPowerFormId } from "../reducers/context";
import { getBrandDetails, getDssSettings, getErrorMessage } from "../reducers/powerform";
import translate, { getUserLocale } from "../lib/translate";
import { languages, languagesMapping } from "../lib/lang";
import { getQueryParam } from '../lib/getQueryParam';

export class App extends React.Component {
  componentDidMount() {
    const embeddedHdnStartValues = document.querySelector('#hdn-start-values') || ""
    const hdnStartValues = embeddedHdnStartValues ? JSON.parse(embeddedHdnStartValues.innerHTML) : []
    const embeddedHdnPersistOriginalValues = document.querySelector('#hdn-persist-original-values') || ""
    const hdnPersistOriginalValues = embeddedHdnPersistOriginalValues ? JSON.parse(embeddedHdnPersistOriginalValues.innerHTML) : []
    this.props.initApp(this.props.params, this.props.query, hdnStartValues, hdnPersistOriginalValues)
  }

  renderChild() {
    if (this.props.errorCode) {
      return <PowerformError errorCode={this.props.ErrorCode} errorMessage={this.props.ErrorMessage} />;
    }
    return <PowerformsSubmissionFormContainer />;
  }

  render() {
    if (this.props.loading) {
      return <LoadingSpinner />;
    }

    const locales = ["en", "ar", "bg", "cs", "da", "de", "el", "en_GB", "es", "es_MX", "et", "fa", "fi", "fr", "fr_CA", "he", "hi", "hr", "hu", "hy", "id", "it", "ja", "ko", "lt", "lv", "ms", "nl", "no", "pl", "pt", "pt_BR", "ru", "sk", "sl", "sr", "sv", "th", "zh_CN", "zh_TW"];

    const currentLocale = (() => {
      // if "en", return "en" (most common case)
      if (this.props.langCode === "en") {
        return "en";
      }

      const langCode = this.props.langCode.slice(3,5);
      const locale = this.props.langCode.slice(6,8);
      const langAndLocale = this.props.langCode.slice(3, 8);

      // if non-locale-specific language, only return language code
      if (["en", "es", "fr", "pt", "zh"].indexOf(langCode) === -1) {
        return langCode;
      } else {
        // also return the locale (maybe)
        if ("zh".indexOf(langCode) !== -1) {
          return langAndLocale;
        }
        if (["CA", "BR", "MX", "GB"].indexOf(locale) !== -1) {
          return langAndLocale;
        } else {
          return langCode;
        }
      }
    })();

    const {
      EnablePowerformsV2_UI_Feature1,
      EnablePowerformsV2_UI_Feature2,
      EnablePowerformsV2_UI_Feature4,
      footerLinks,
      langCode,
      onChangeLanguage,
      brandLogo_AuthWhiteBorder,
      powerformId,
      query
    } = this.props;

    const links = (() => {
      if (footerLinks) {
        const reshapedFooterLinks = footerLinks.map(fl => {
          if (fl) {
            return {id: fl.name.split(" ").join("_").toUpperCase(), customTranslate: () => fl.text, url: fl.url}
          }
          return {}
        })
        return [ ...Footer.defaultFooterLinks, ...reshapedFooterLinks]
      } else {
        return Footer.defaultFooterLinks
      }
    })();

    const env = getQueryParam(query, "env").toLowerCase()

    return (
      <TranslationProvider value={text => translate(text, this.props.langCode, EnablePowerformsV2_UI_Feature2, powerformId, env, EnablePowerformsV2_UI_Feature4)}>
        <Theme 
          accountBranding={{
            buttonPrimaryText: this.props.btnPrimaryTxtColor,
            buttonPrimaryBackground: this.props.btnPrimaryColor,
            headerBackground: this.props.headerBgColor,
            headerText: this.props.headerTxtColor,
            image: this.props.imgUrl,
            buttonMainBackground: "#ffffff",
            imageBackgroundWhite: brandLogo_AuthWhiteBorder === "true"
          }}
          enableFontFaceDeclarations
          enableGlobalCss
          enableNormalizeCss
          docuSignTheme={InkDocuSignTheme} >
          <NDSEHeaderContainer />
          <NotificationsContainer />
          <div className="site_content">
            <div className="content_wrap">{this.renderChild()}</div>
          </div>
          <Footer
            links={links}
            locales={locales}
            currentLocale={currentLocale}
            onLocaleSelected={localeSelected => onChangeLanguage(languagesMapping[localeSelected])}
            hidePoweredBy={false}
            onLinkClicked={() => {}}
            rebrand2024
          />
        </Theme>
      </TranslationProvider>
    );
  }
}

const AppComponent = connect(
  (state, ownProps) => ({
    langCode: state.langCode || getUserLocale(),
    loading: state.loading,
    params: ownProps.match.params,
    query: ownProps.location.search,
    errorCode: getErrorCode(state),
    errorMessage: getErrorMessage(state),
    powerformId: getPowerFormId(state.context),
    ...getBrandDetails(state.powerform),
    ...getDssSettings(state.powerform),
  }),
  dispatch => bindActionCreators({
    initApp: actions.initApp,
    onChangeLanguage: actions.changeLocale
  }, dispatch)
)(App);

export default () => (
  <Provider store={store({})}>
    <ConnectedRouter history={history}>
      <div className="site site-panel">
        <div className="site_wrap">
          <Switch>
            <Route
              exact
              path="/:server/:powerformId"
              component={AppComponent}
            />
            <Route exact path="/:powerformId" component={AppComponent} />
          </Switch>
        </div>
      </div>
    </ConnectedRouter>
  </Provider>
);
